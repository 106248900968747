<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-statement @hidden="selectedStatement = undefined" id="edit-statement" :statement="selectedStatement" />
    <sticky-header :title="$route.meta.title"></sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-2">
        <b-col cols="12" md="9">
          <b-form-input debounce="500" @update="load" placeholder="Search for statement..." type="text" v-model="query" />
        </b-col>
        <b-col cols="12" md="3">
          <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown" /><fa-icon class="ml-2" icon="chevron-up" v-else /></b-button>
        </b-col>
      </b-row>
      <b-card v-if="filtersShown">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="ownerEnabled"><label class="mb-0" for="client">Owner</label></b-checkbox>
              </template>
              <user-search :disabled="!ownerEnabled" id="client" type="client" v-model="selectedOwner" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="periodEnabled"><label class="mb-0" for="period">Period</label></b-checkbox>
              </template>
              <b-form-select :disabled="!periodEnabled" id="period" v-model="selectedPeriod" :options="filterPeriods" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="propertyEnabled"><label class="mb-0" for="property">Property</label></b-checkbox>
              </template>
              <property-search :disabled="!propertyEnabled" id="property" v-model="selectedProperty" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center mt-2 py-5" v-if="!loading && data.length === 0">
        <p class="h3 font-weight-bold">No statements found.</p>
        <p class="text-muted mb-0">You may not see details of financials on bookings until they start.</p>
      </b-card>
      <b-list-group class="mt-2 mb-4" v-if="!loading">
        <b-list-group-item class="d-flex" :key="item.id" v-for="item in data">
          <div class="align-self-center flex-fill">
            <p class="h5">Statement {{ item.statement_no }} for {{ moment(item.issued_at).format('MMM YYYY') }} for {{ item.property.name }}</p>
            <p class="mb-0 text-muted">{{ item.property.address.address_snippet }}</p>
          </div>
          <div class="align-self-center d-flex flex-md-row flex-column">
            <b-button class="mb-1 mb-md-0 ml-md-1" :href="`/api/statements/${item.id}/pdf?download=false`" target="_blank" variant="light" v-b-popover.top.hover="`View Statement`"><fa-icon class="mr-2" icon="file-invoice-dollar" /> View</b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" :href="`/api/statements/${item.id}/csv`" variant="light" v-b-popover.top.hover="`Download CSV Statement`"><fa-icon class="mr-2" icon="download" /> CSV</b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" :href="`/api/statements/${item.id}/pdf`" variant="light" v-b-popover.top.hover="`Download PDF Statement`"><fa-icon class="mr-2" icon="download" /> PDF</b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click="onClickSendEmail(item.id)" target="_blank" variant="primary" v-b-popover.top.hover="`Send Email`"><fa-icon class="mr-2" icon="envelope" /> Send Email</b-button>
            <b-button class="mb-1 mb-md-0 ml-md-1" @click="edit(item.id)" variant="primary" v-b-popover.top.hover="`Edit Statement`"><fa-icon class="mr-2" icon="pen" /> Edit Lines</b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditStatement from './Modals/EditStatement'
import PropertySearch from '../components/PropertySearch'
import UserSearch from '../components/UserSearch'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { EditStatement, PropertySearch, StickyHeader, UserSearch },
  data() {
    const periods = Array.from(Array(72).keys()).map(m => moment().subtract(m, 'months'))

    return {
      query: '',
      ownerEnabled: false,
      periodEnabled: false,
      propertyEnabled: false,
      selectedOwner: undefined,
      selectedPeriod: moment().format('YYYY-MM-[01]'),
      selectedProperty: undefined,
      filtersShown: false,
      filterPeriods: periods.map(p => ({ text: p.format('MMM YYYY'), value: p.format('YYYY-MM-[01]') })),
      selectedStatement: {},
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('statements', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('statements', ['sendEmail', 'fetch']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'statements', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'statements', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    edit(id) {
      this.selectedStatement = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'statements.update' && this.$route.params.statementId === id)) {
        this.$router.push({ name: 'statements.update', params: { statementId: id } });
      }
    },
    onClickSendEmail(statementId) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to send this statement to the property owner?", {
        title: 'Send Statement Email?',
        okText: 'Yes, Send',
        cancelText: 'No',
        cancelVariant: 'light',
        centered: true
      }).then(should => {
        if (!should) {
          return;
        }

        return this.sendEmail(statementId);
      });
    },
    async load() {
      await this.fetch(Object.fromEntries(Object.entries({
        query: this.query,
        page: this.$route.query.page,
        period: this.periodEnabled ? this.selectedPeriod : undefined,
        owner_id: this.ownerEnabled ? this.selectedOwner : undefined,
        property_id: this.propertyEnabled ? this.selectedProperty : undefined,
      }).filter(([,v]) => v !== undefined)));
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    async ownerEnabled() { await this.load() },
    async periodEnabled() { await this.load() },
    async propertyEnabled() { await this.load() },
    async selectedOwner() { await this.load() },
    async selectedPeriod() { await this.load() },
    async selectedProperty() { await this.load() },
  }
}
</script>

<style>

</style>
