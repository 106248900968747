<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="xl" title="Add/Remove Statement Items" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card class="my-2" :key="item.id" v-for="(item, ind) in editedStatement.items">
            <b-row>
              <b-col cols="3" v-if="(item?.amount ?? 0) < 0">
                <b-form-group label="Date" :invalid-feedback="getValidationMessage('deducted_at')" :state="!validationErrors.deducted_at">
                  <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="item.deducted_at" />
                </b-form-group>
              </b-col>
              <b-col :cols="(item?.amount ?? 0) < 0 ? 6 : 7">
                <b-form-group label="Name" :invalid-feedback="getValidationMessage('name')" :state="!validationErrors.name">
                  <b-form-input type="text" v-model="item.name" :disabled="item.from_type == 'App\\Models\\Job'" />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Amount" :invalid-feedback="getValidationMessage('amount')" :state="!validationErrors.amount">
                  <b-input-group prepend="£">
                    <b-form-input type="number" step="0.01" v-model="item.amount" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="2" v-if="item.amount > 0">
                <b-form-group label="Management" :invalid-feedback="getValidationMessage('amount_mgmt')" :state="!validationErrors.amount_mgmt">
                  <b-input-group prepend="£">
                    <b-form-input type="number" step="0.01" v-model="item.amount_mgmt" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="1" class="d-flex flex-row">
                <b-button block class="align-self-end mb-3" @click="editedStatement.items.splice(ind, 1)" variant="danger"><fa-icon icon="trash" /></b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-form-checkbox class="mr-1" v-model="editedStatement.approved">Approved</b-form-checkbox>
        <b-form-checkbox class="mr-1" v-model="editedStatement.settled">Settled</b-form-checkbox>
        <b-button class="mx-2" @click.prevent="editedStatement.items.push({name: '', amount: 0})" variant="light"><fa-icon class="mr-2" icon="plus"/>Add Line</b-button>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveUser" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">Update</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';

export default {
  mixins: [handleErrors],
  emits: ['hidden'],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedStatement: {
        items: [],
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    statement: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.statementId && this.statement) {
      this.loading = true;

      try {
        this.editedStatement = await this.fetchSingle(this.$route.params.statementId);
        this.loading = false;
      } catch (err) {
        this.error = "Can not load statement. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('statements', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedStatement = {
        items: [],
      };
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveUser() {
      this.saving = true;
      this.updateOrCreate(this.editedStatement).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    statement(value) {
      this.editedStatement = { items: [], ...value };
    }
  }
}
</script>
